import _get from 'lodash/get'
import { vendorInvoice } from '@/services/Vendors/VendorInvoices/store'
import kms from '@/services/kms'
import { parseDate } from '@/utilities/Date/parse'
import Download from '../components/Download'
import { base64toBlob, showPdf } from '@/services/blobutility'
import { notifyProblem, notifyMessage, notifyWarning } from '@/services/notify'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      var dateFormat = 'LL'

      const invoiceNumber = _get(entry, 'invoiceNumber', '')
      const payeeInformation = _get(entry, 'payeeInformation', '')
      const postingDate = parseDate(_get(entry, 'postingDate', null), dateFormat)

      return {
        invoiceNumber: invoiceNumber,
        payeeInformation: payeeInformation,
        postingDate: postingDate,
        document: {
          component: Download,
          props: {
            documentID: _get(entry, ['invoiceDocumentID'], 0),
            apInvoiceID: _get(entry, 'apInvoiceID', 0),
            downloadPdf: apInvoiceID => {
              this.downloadPdf(apInvoiceID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  async getPayeeID() {
    await vendorInvoice
      .dispatch('getPayeeById', {
        vendorID: this.vendorID
      })
      .then(({ result }) => {
        if (result) {
          this.payeeID = result.apPayeeID
          if (this.isDebug == true) console.debug('getPayeeById=' + this.payeeID)
        }
      })
  },

  async loadVendorInvoices() {
    if (this.isDebug == true) console.debug('in loadVendorInvoices()...')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await this.getPayeeID()

    await vendorInvoice
      .dispatch('getVendorInvoiceList', {
        payeeID: this.payeeID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })

    loadingComponent.close()
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    if (this.isDebug == true) console.debug('params vendorID=' + this.vendorID)

    this.rows = []

    this.loadVendorInvoices()

    this.loading = false
  },

  async downloadPdf(apInvoiceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!apInvoiceID || apInvoiceID == undefined) {
      notifyProblem('The vendor invoice is invalid for the download.')
    }

    try {
      const params = {
        apInvoiceID: apInvoiceID,
        asBase64: true
      }

      const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded the vendor invoice.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the vendor invoice.')
    }
  }
}
