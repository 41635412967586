import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  vendorID: 0,
  payeeID: 0,
  apInvoiceID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'invoiceNumber',
      label: 'Invoice #',
      aria: 'Invoice #',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'payeeInfo',
      label: 'Payee Info',
      aria: 'Payee Info',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'postingDate',
      label: 'Posting Date',
      aria: 'Posting Date',
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
