<template>
  <div>
    <span
      v-if="(typeof apInvoiceID === 'string' || typeof apInvoiceID === 'number') && documentID > 0"
    >
      <button
        class="button is-info is-light is-small is-outline is-rounded"
        @click.prevent="downloadPdf(apInvoiceID)"
      >
        Pdf
      </button>
    </span>
    <span v-else>
      &nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    apInvoiceID: [Number, String],
    documentID: [Number, String],
    downloadPdf: Function
  }
}
</script>
